import React from 'react';
import './stylesheets/HomePage.css';

class DonatePage extends React.Component {
  // ... existing code ...

  render() {
    return (
      <div className="homepage-container">
        <h1>Support Our Cause</h1>
        <p>Your donation will help fund web advertising to grow global interest, targeted outreach to qualified leads, and other pressing work that accelerates our mission. Every contribution makes a difference and helps us move faster towards our goals (saving lives).</p>
        
        <h2>Credit Card</h2>
        <a href="https://buy.stripe.com/4gw28T7L0dBA80wcMM" className="stripe-payment-link">
          Donate with Stripe
        </a>


        <h2>Venmo</h2>
        
        <a href="venmo://paycharge?txn=pay&recipients=joseph-combs-1" className="venmo-button">
          Donate with Venmo
        </a>
      </div>
    );
  }
}

export default DonatePage;

