import React from 'react';
import './stylesheets/HomePage.css';

import './stylesheets/PlanPage.css';
import ImplementationPlan from './ImplementationPlan';

class PlanPage extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  componentDidMount(props) {    
  }
  
  componentWillUnmount() {
  }

  render() {
    return (
      <div className="homepage-container">
        <ImplementationPlan />
      </div>
    );
  }
}

export default PlanPage;

